import React, { useContext, useEffect, useState } from 'react'
import Step from '../components/Step'
import Input from '../components/Input'
import ButtonContainer from '../components/ButtonContainer'
import { Button, InputGroup } from 'react-bootstrap'
import Context, { AppState } from '../Context'
import Checkbox from '../components/Checkbox'
import Services from '../services'
import Validation from '../../validation'
import env from '../../env'
import { useBSBMask } from '../Hooks'
import Autosuggest from 'react-autosuggest'
import { CustomInputGroup } from '../components/CustomInputGroup'
import SelectInput from '../components/SelectInput'
import { BSBAutocompleteResponseItem } from '../services/bsbAutocomplete'

// Fields to send
const title = 'title'
const firstName = 'firstName'
const lastName = 'lastName'
const phone = 'phone'
const address = 'address'
const suburb = 'suburb'
const state = 'state'
const postcode = 'postcode'
const financialInstitutionName = 'financialInstitutionName'
const bsb = 'bsb'
const accountNumber = 'accountNumber'
const accountName = 'accountName'
const agreementCheck1 = 'agreementCheck1'
const agreementCheck2 = 'agreementCheck2'

const _foundFinancialInstitutionName = "_financialInstitutionName"


const STATES = ["NSW", "VIC", "QLD", "WA", "SA", "ACT", "TAS", "NT"].map(state => ({
    type: "STATE",
    value: state,
    description: state,
}))


function validate(fields:Record<any, string>){
    return Validation.validate(fields, {
        [title]:[
            Validation.enforce.required("Required"),
            Validation.enforce.lengthLessThanEqual(10)("Please enter a shorter title")
        ],
        [firstName]:[
            Validation.enforce.required("Required"),
            Validation.enforce.name("Please enter only valid characters"),
            Validation.enforce.lengthLessThanEqual(100)("Please enter a shorter first name")
        ],
        [lastName]:[
            Validation.enforce.required("Required"),
            Validation.enforce.name("Required"),
            Validation.enforce.lengthLessThanEqual(100)("Please enter a shorter first name")
        ],
        [phone]:[
            Validation.enforce.required("Required"),
            Validation.enforce.lengthLessThanEqual(50)("Please enter a shorter phone")
        ],
        [address]:[
            Validation.enforce.required("Required"),
            Validation.enforce.lengthLessThanEqual(100)("Please enter a shorter email address")
        ],
        [suburb]:[
            Validation.enforce.required("Required"),
            Validation.enforce.lengthLessThanEqual(25)("Please enter a shorter suburb")
        ],
        [state]:[
            Validation.enforce.required("Required"),
        ],
        [postcode]:[
            Validation.enforce.required("Required"),
            Validation.enforce.numChars(4)("Please enter a valid post code"),
        ],
        [financialInstitutionName]:[
            Validation.enforce.required("Required"),
            Validation.enforce.lengthLessThanEqual(100)("Please enter a shorter name")
        ],
        [bsb]:[
            Validation.enforce.required("Required"),
            Validation.enforce.bsb("Please enter a valid BSB"),
            Validation.enforce.valueExists(fields[financialInstitutionName])("Please enter a valid BSB"),
        ],
        [accountNumber]:[
            Validation.enforce.required("Required"),
            Validation.enforce.accountNumber("Please enter an account number between 6 and 9 digits"),
        ],
        [accountName]:[
            Validation.enforce.required("Required"),
            Validation.enforce.accountName("Please enter a shorter account name"),
        ],
        [agreementCheck1]:[
            Validation.enforce.required("Required"),
        ],
        [agreementCheck2]:[
            Validation.enforce.required("Required"),
        ],
    });
}


async function fetchBind(app:AppState){
    return Services.bind({
        token: app.params.externalId,
        details:{
            instalmentPaymentType:       'DIRECT_DEBIT',
            [title]:                     app.fields[title],
            [firstName]:                 app.fields[firstName],
            [lastName]:                  app.fields[lastName],
            [phone]:                     app.fields[phone],
            [address]:                   app.fields[address],
            [suburb]:                    app.fields[suburb],
            [state]:                     app.fields[state],
            [postcode]:                  app.fields[postcode],
            [financialInstitutionName]:  app.fields[financialInstitutionName],
            [bsb]:                       app.fields[bsb],
            [accountNumber]:             app.fields[accountNumber],
            [accountName]:               app.fields[accountName],
            [agreementCheck1]:           app.fields[agreementCheck1],
            [agreementCheck2]:           app.fields[agreementCheck2],
        }
    });
}


function useConfirmPayment(){
    const {app, dispatch} = useContext(Context)
    
    return async function (){
        const errors = validate(app.fields);
        if(errors){
            dispatch({ type: 'validation.fail', errors });
            return;
        }

        dispatch({ type:'loading.start', id: "payment-submission" })

        const removeLoadingSpinner = () => dispatch({ type: 'loading.end', id: "payment-submission" })

        try{
            const result = await fetchBind(app)
            
            if(result && 'successful' in result && result.successful==true){
                dispatch({ type: 'app.success', token:result.token, policyNumber:result.policyNumber });
            }else if(result==null){
                console.log("null response")
                dispatch({type: 'alert.show', info: {message: app.messages.genericError}})
                removeLoadingSpinner()
            }else if('reason' in result){
                console.log('response with reason')
                dispatch({ type: 'alert.show', info:{ message: result.reason }})
                removeLoadingSpinner()
            }else if('message' in result){
                console.log('response with message')
                dispatch({ type: 'alert.show', info:{ message: app.messages.genericError }})
                removeLoadingSpinner()
            }else if('errors' in result){
                console.log('response with errors')
                const message = Object.keys(result.errors).map(key => <li>{key}: - {result.errors[key]}</li>)
                dispatch({ type: 'alert.show', info:{
                    message:<ul>{ message  }</ul>
                }})
                removeLoadingSpinner()
            } 

        }catch(e){
            removeLoadingSpinner()
            dispatch({ type: 'alert.show', info:{
                message: app.messages.genericError
            }})
        }
    }
    
}


function useFindFinancialInstitutionName(){
    const {app, dispatch} = useContext(Context)
    const bsbValue = app.fields[bsb];

    useEffect(() => {
        if(bsbValue && bsbValue.length==7){
        }else{
            dispatch({
                type:'fields.set',
                values:{
                    [_foundFinancialInstitutionName]:false,
                    [financialInstitutionName]: ""
                }
            })
        }
    }, [bsbValue])
}



function BSBInput(){
    type SuggestionValue = { bsb:string, financialInstitution:string};
    type Suggestion = {value:SuggestionValue, label:string}
    const {app, dispatch} = useContext(Context)
    const bsbValue = app.fields[bsb];
    const [ suggestions, setSuggestions ] = useState([] as Suggestion[])
    const hasErrors = !!app.validation.errors?.[bsb]
    
    useBSBMask(bsb);
    useFindFinancialInstitutionName();


    return (
        <div className="input">
            <CustomInputGroup name={bsb} label="BSB Number">
                <Autosuggest
                    suggestions={ suggestions }
                    onSuggestionsClearRequested={ () => setSuggestions([]) }
                    getSuggestionValue={ suggestion => suggestion.value.bsb }
                    renderSuggestion={ suggestion => <div className="address-suggestion">{suggestion.label}</div> }
                    onSuggestionsFetchRequested={ async (e) => {
                        const query = e.value
                        if(!query || query.length < 2) return;
                        const response = await Services.bsbAutocomplete(query)
                        const newSuggestions:Suggestion[] = response.map(entry => ({
                            value: entry,
                            label: entry.bsb + " - " + entry.name + ", " + entry.financialInstitution,
                        })).slice(0, 5);
                        setSuggestions(newSuggestions);
                    } }
                    onSuggestionSelected={(e, {suggestion}) => {
                        dispatch({
                            type:'fields.set',
                            values:{
                                [bsb]: suggestion.value.bsb,
                                [financialInstitutionName]: suggestion.value.financialInstitution,
                                [_foundFinancialInstitutionName]: true,
                            }
                        })
                    }}
                    inputProps={{
                        value: bsbValue || "",
                        id:"input-"+address,
                        type:'text',
                        className:`form-control ${hasErrors ? 'is-invalid' : ''}`,
                        onChange: (event:any) => dispatch({ type:'fields.setValue', name:bsb, value:event.target.value})
                    }}
                />
            </CustomInputGroup>
        </div>
    )
}


export default function(){
    const {app, dispatch} = useContext(Context)
    const fields = app.fields
    
    const onConfirmPayment = useConfirmPayment();
    const onCancel = () => dispatch({ type: 'app.cancel' })
    const flexAgreementLabel = <span>I/We have read and understood the CHUiSAVER Underwriting Agency Pty Ltd Direct Debit Request (DDR) Service Agreement found <a href="https://www.flexinsurance.com.au/direct-debit-request-ddr-service-agreement" target="_blank">here</a> and the Important notice found below. I/We also authorise CHUiSAVER Underwriting Agency Pty Ltd to alter the amount of the debit if I/we request a change to the sums insured.</span>;
    const chuAgreementLabel = <span>I/We have read and understood the CHU Underwriting Agencies Pty Ltd Direct Debit Request (DDR) Service Agreement found <a href="https://www.chu.com.au/direct-debit-request-ddr-service-agreement" target="_blank">here</a> and the Important notice found below. I/We also authorise CHU Underwriting Agencies Pty Ltd to alter the amount of the debit if I/we request a change to the sums insured.</span>;
    return (
        <Step>
            <h2>Direct Debit Request</h2>
                { env.BRAND==="flex" && <p>By completing this form you authorise and request us, CHUiSAVER Underwriting Agency Pty Ltd (APCA User ID 625789) trading as Flex Insurance to arrange funds to be debited from your nominated account to the financial institution identified and as described below, through the Bulk Electronic Clearing System or by any other means. This authorisation will remain active in accordance with the terms and conditions in the Direct Debit Service Agreement and this DDR form.</p>}
                { env.BRAND==="chu"  && <p>By completing this form you authorise and request us, CHU Underwriting Agencies Pty Ltd (APCA User ID 625788) to arrange funds to be debited from your nominated account to the financial institution identified and as described below, through the Bulk Electronic Clearing System or by any other means. This authorisation will remain active in accordance with the terms and conditions in the Direct Debit Service Agreement and this DDR form.</p>}
                
                <h4 className="pt-4 pb-2">Section A: My Details</h4>
                
                <div className="row">
                    <div className="col-md-6">
                        <Input name={title} label="Title"/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <Input name={firstName} label="First name"/>
                    </div>
                    <div className="col-md-6">
                        <Input name={lastName} label="Last name"/>
                    </div>
                    <div className="col-md-6">
                        <Input name={phone} label="Phone number"/>
                    </div>
                </div>
                <div className="row">
                    
                    <div className="col-md-12" style={{zIndex:1}}>
                        <Input name={address} label="Address" type='address'/>
                    </div>
                    <div className="col-md-6">
                        <Input name={suburb} label="Suburb"/>
                    </div>
                    <div className="col-md-3">
                        <SelectInput name={ state } options={ STATES } label="State" />
                    </div>
                    <div className="col-md-3">
                        <Input name={postcode} label="Postcode" type='postcode'/>
                    </div>
                    
                </div>
                <h4 className="pt-4 pb-2 mt-4">Section B: Authority to</h4>
                <div>
                    <p>Authorised Financial Insitution</p>
                    <p>Direct debit your Bank, Building Society or Credit Union account.</p>
                    { env.BRAND ==='flex' && <p>I/We request CHUiSAVER Underwriting Agency Pty Ltd (APCA User ID 625789) trading as Flex Insurance to debit funds from my/our nominated account according to the details specified below.</p> }
                    { env.BRAND ==='chu'  && <p>I/We request CHU Underwriting Agencies Pty Ltd (APAC User ID 625788) to debit funds from my/our nominated account according to the details specified below.</p> }
                    
                    <div className="row">
                        <div className="col-md-6">
                            {<BSBInput />}
                        </div>
                        <div className="col-md-6">
                            <Input name={accountNumber} label="Account Number"/>
                        </div>
                        <div className="col-md-12">
                            <Input name={financialInstitutionName} label="Financial Institution Name where account held" readOnly={!!fields[_foundFinancialInstitutionName]} disabled={!!fields[_foundFinancialInstitutionName]}/>
                        </div>
                        <div className="col-md-12">
                            <Input name={accountName} label="Account in the name(s) of:"/>
                        </div>
                    </div>
                    { env.BRAND ==='flex' && <Checkbox name={agreementCheck1} label={flexAgreementLabel}/>}
                    { env.BRAND ==='chu'  && <Checkbox name={agreementCheck1} label={chuAgreementLabel}/>}
                    <Checkbox name={agreementCheck2} label="I/We agree to this DDR Service Agreement" />
                </div>
                <h4 className="pt-4 pb-2 mt-4">Important notice</h4>
                <div>
                    <ul>
                        <li>Direct Debit deductions will be made through the Bulk Electronic Clearing System (BECS) and will be subject to Drawing arrangements. Please ensure sufficient funds are available. Our standard policy wording contains a provision that where a premium instalment remains outstanding for a period in excess of one month the insurance cover may be cancelled.</li>
                        <li>Contact your financial institution if you are uncertain how to complete this Direct Debit Request form</li>
                    </ul>

                    <p>If more than one person is required to authorise Direct Debits Requests on this account you must obtain the authorisation of all required parties before completing this form. By clicking "I/We agree to this DDR Service Agreement" you are confirming you have obtained this authorisation.</p>
                    <p>By clicking "I/We agree to this DDR Service Agreement" you:</p>
                    { env.BRAND ==='flex' && <p>1) have understood and agreed to the terms an conditions governing the debit arrangements between you and Flex Insurance as set out in this Direct Debit Request and in the Direct Debit Request Service Agreement; and</p> }
                    { env.BRAND ==='chu'  && <p>1) have understood and agreed to the terms an conditions governing the debit arrangements between you and CHU Underwriting Agencies Pty Ltd as set out in this Direct Debit Request and in the Direct Debit Request Service Agreement; and</p> }
                    <p>2) understand you are executing this document by electronic signature and you are aware that by electronically signing this document you are executing a legally binding document.</p>
                </div>

                <ButtonContainer>
                    <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                    <Button variant="primary" onClick={onConfirmPayment}>Confirm payment</Button>
                </ButtonContainer>
        </Step>
    )
}