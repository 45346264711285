import env from '../../env';
import Request from './request'
import Config from '../ConfigInstalments'


export type PaymentInitiateResult = {
    paymentClientToken:string,
    paymentEnvironment:any,
}


export default async () => {
    const url = Config.endpoints.paymentInitiate;

    let data:PaymentInitiateResult = await Request.post(url, {});
    return data;
}
