import React from 'react'
import { Switch, Route, Redirect, HashRouter } from 'react-router-dom'

import RouteConfirm from './steps/instalments-confirm'
import RouteDirectDebit from './steps/instalments-direct-debit'
import RouteCreditCard from './steps/instalments-credit-card'

export const routes = [
    '/confirm',
    '/direct-debit',
    '/credit-card',
] as const;


export type RouteName = typeof routes[number]


export default () => {
    return (
        <Switch>
            <Route path={"/confirm" as RouteName}       component={RouteConfirm} />
            <Route path={"/direct-debit" as RouteName}  component={RouteDirectDebit} />
            <Route path={"/credit-card" as RouteName}   component={RouteCreditCard} />
            <Redirect from="*" to={"/confirm" as RouteName} />
        </Switch>
    );
}
