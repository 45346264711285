import env from './env'

let devTools:any = null;

function connect(){
    if(env.ENVIRONMENT === 'production') return null;
    if(devTools) return
    const win:any = window;
    devTools = win.__REDUX_DEVTOOLS_EXTENSION__
    if(!devTools) return;
    
    let tools = devTools.connect()
}

function update(command:string, state:any){
    if(!devTools) return;
    devTools.send(command, state);
}

connect();

export default {
    update,
}