import React from 'react';
import ReactDOM from 'react-dom';
import App from './form-instalments/AppInstalments'

import '@fortawesome/fontawesome-free/js/all.js';
import 'core-js'; // IE11 Polyfill
import env from './env/'
import { createInitialState } from './form-instalments/Context';


function setupJqueryForBootstrap4(){
    var $ = require('jquery');
    var win:any = window;
    win.$ = $;
}


function logEnv(){
    if(env.ENVIRONMENT !== "production"){
        console.log(process.env);
    }
}


function setupTheme(){
    // This line must reference the env variable directly so
    // that webpack can substitute the string inline
    require(process.env.REACT_APP_BRAND_STYLE!);
}


async function setupApp(){
    const initialState = await createInitialState();
    ReactDOM.render(<App initialState={initialState} />, document.getElementById('root'));
}


function enableHotReloading(){
    if(env.ENVIRONMENT === "test"){
        var mod:any = module;
        if (mod["hot"]) {
            mod["hot"].accept()
        }
    }
}


export default {
    setupJqueryForBootstrap4,
    logEnv,
    setupTheme,
    setupApp,
    enableHotReloading,
}