import React from 'react';

type Props = {}


const ButtonContainer:React.FunctionComponent<Props> = ({ children }) => (
    <div className="button-container">
        { children }
    </div>
)

export default ButtonContainer;
