import CurrencyFormatter from 'currency-formatter'

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

export function getUTCDateString(date:Date){
    const twoDigit = (num:number) => num<10 ? ("0"+num) : ""+num;
    return twoDigit(date.getUTCDate()) + "/" + twoDigit(date.getUTCMonth()+1) + "/" + date.getUTCFullYear()
}

export function twoDigits(value:number){
    if(value < 10) return "0"+value;
    return ""+value;
}

export function prettyDateFromArray(value:[number,number,number]){
    return twoDigits(value[2]) +'/'+ twoDigits(value[1]) +'/'+ twoDigits(value[0])
}

export function prettyDate(date:Date){
    return `${dayNames[date.getUTCDay()]} ${date.getUTCDate()} ${monthNames[date.getUTCMonth()]} ${date.getUTCFullYear()}`
}

export function appendParams(url:string, params:Record<string, string>){
    Object.keys(params).forEach(key => {
        if(url.indexOf('?')!==-1) url += "&"
        else url += "?"
        url += key + "=" + params[key];
    })
    return url;
}

export function dollarText(amount:number){
    return '$' + CurrencyFormatter.format(amount, {
        thousand: ",",
        precision: 2
    })
}

export function redirectError(url:string, reason:string){
    const resultURL = appendParams(url, {reason:encodeURI(reason)})
    window.location.href = resultURL;
}


export function redirectSuccess(url:string, policyNumber:string){
    const resultURL = appendParams(url, { policyNumber })
    window.location.href = resultURL;
}


export function redirectCancel(url:string){
    const resultURL = appendParams(url, {})
    window.location.href = resultURL;
}