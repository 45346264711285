import querystring from 'querystring';
import axios from 'axios';

type Method = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';

async function request<P extends object>(method:Method, url:string, payload?:P){
    const response = await axios(url, {
        method,
        //mode: 'no-cors',
        data: payload ? JSON.stringify(payload) : undefined,
        validateStatus: (status) => true,
        headers:{
            'Content-Type': 'application/json'
        }
    });
    try{
        const json = await response.data;
        return json;
    }catch(e){
        return null; // HACK: We can't rely on status codes
    }
}


async function get(url:string, params?:querystring.ParsedUrlQueryInput){
    url = params
        ? url+'?'+querystring.stringify(params)
        : url;
    return request('GET', url);
}


async function post<P extends object>(url:string, payload:P){
    return request('POST', url, payload);
}


export default {
    get,
    post,
}