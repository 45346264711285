import React from 'react';
import { useContext } from "react"
import { FormControl } from "react-bootstrap"
import Context from "../Context"
import CurrencyFormatter from 'currency-formatter'
import { CustomInputGroup } from './CustomInputGroup';


export type Type = // Custom types
            | "dollar"
            | "postcode"
            | "address"
            | "year"
            | "percent"
            | "textarea"
            // Native types
            | "text"
            | "search"
            | "url"
            | "tel"
            | "password"
            | "number"
            | "datetime-local"
            | "date"
            | "month"
            | "week"
            | "time"
            | "email"

type Props = {
    name: string,
    label: React.ReactNode,
    type?: Type,
    readOnly?: boolean,
    disabled?: boolean,
}


const Input = ({name, label, type, readOnly, disabled}:Props) => {
    const {app, dispatch} = useContext(Context)
    const value = app.fields[name];
    const errors = app.validation.errors?.[name];
    const [inputMask, outputMask] = getMask(type);
    
    const setField = (value:any) => {
        dispatch({
            type:'fields.setValue',
            name,
            value,
        })
    }


    return (
        <CustomInputGroup name={name} label={label}>
            <FormControl 
                readOnly={readOnly}
                disabled={disabled}
                isInvalid={!!errors}
                id={name}
                value={inputMask(value)}
                onChange={(e:any)=>setField(outputMask(e.target.value))}
                inputMode={getInputMode(type)}
                type={getInputType(type)}
                pattern={getInputPattern(type)}
            />
        </CustomInputGroup>
    )
}


export const getInputType = (type:Type|undefined) => {
    switch(type){
        case "dollar": return "text" as const;
        case "postcode": return "number" as const; 
        case "address": return "text" as const;
        case "email": return "email" as const;
        case "date": return "text" as const; // Overriding
        default: return "text" as const;
    }
}


export const getInputPattern = (type:Type|undefined) => {
    switch(type){
        case "dollar"   : return '[0-9]*'
        case "postcode" : return '[0-9]*'
        case "year"     : return '[0-9]*'
        case "percent"  : return '[0-9]*'
        default: return '';
    }
}


export const getInputMode = (type:Type|undefined) => {
    switch(type){
        case "dollar"   : return "numeric" as const
        case "postcode"   : return "numeric" as const
        case "year"   : return "numeric" as const
        case "percent"   : return "numeric" as const
        case "email"   : return "email" as const
    }
    return 'text' as const
}


export function getMask(type:Type|undefined):[(value:any)=>any, (string:any)=>any]{
    switch(type){
        case 'number':
            return [
                identity,
                fromNumber,
            ]
        case 'dollar':
            return [
                fromCurrency,
                toCurrency
            ]
        case 'date':
            return [
                identity,
                fromDate,
            ]
        case 'year':
            return [
                identity,
                fromYear,
            ]
        case 'postcode':
            return [
                identity,
                fromPostcode,
            ]
        case 'percent':
            return [
                identity,
                fromPercent,
            ]
        default: return [
            identity,
            identity
        ]
    }
}


// Masking

function toCurrency(value:string){
    value = stripNonNumberCharacters(value);
    if(value === "" || value === null || value===undefined) return value;
    return parseFloat(value)
}

function fromNumber(value:string){
    value = stripNonNumberCharacters(value);
    if(value === null || value === "" || value===undefined) return value;
    return asNumber(value);
}

function fromCurrency(value:string){
    value = stripNonNumberCharacters(value);
    if(value === null || value === "" || value===undefined) return value;
    const num = asNumber(value);
    return CurrencyFormatter.format(num, {
        thousand: ",",
        precision: 0
    })
}

function fromYear(value:string){
    value = stripNonNumberCharacters(value);
    if(value === null || value===undefined || value === "") return value;
    const sliced = value.slice(0, Math.min(4, value.length));
    return parseInt(sliced);
}

function fromPostcode(value:any){
    if(value === null || value===undefined || value === "") return value;
    const num = stripNonNumberCharacters(value);
    return num.slice(0, Math.min(4, num.length));
}

function fromPercent(value:any){
    if(value === null || value===undefined || value === "") return value;
    return asNumber(stripNonNumberCharacters(value));
}

function fromCurrencyDecimal(value:any){
    value = stripNonDateCharacters(value)
    if(value === null || value === "" || value===undefined) return value;
    return CurrencyFormatter.format(value, {
        thousand: ",",
        precision: 2
    })
}

function identity(value:any){
    return value;
}

function fromDate(str:string){
    str = stripNonDateCharacters(str)
    if(str === "" || str === null || str===undefined) return str;

    var sanitised = ""
    for(var i = 0; i < str.length; i++){
        if(i === 2 || i === 5){
            if(str[i] === "/") sanitised += "/"
            else sanitised += "/" + str[i]
        }else{
            if(str[i] !== "/") sanitised += str[i]
        }
    }

    sanitised = sanitised.slice(0, 10)
    return sanitised
}

export function asNumber(str:string){
    if(typeof str === 'number') return str;
    str = stripNonNumberCharacters(str);
    if(str === "" || str === null || str===undefined) throw new Error("Cannot return number");
    return parseFloat(str);
}

export function stripNonNumberCharacters(str:string){
    if(typeof str !== 'string') return str;
    if(str === null || str === "" || str===undefined) return str;
    return str.replace(/\D/g, '')
}

export function stripNonDateCharacters(str:string){
    if(str === null || str === undefined || str==="") return str;
    return str.replace( /([^0-9/\//])/g, '' )
}

export default Input;