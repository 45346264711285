import Config from "../ConfigInstalments";
import Request from "./request";

export type BSBAutocompleteResponseItem = {
    bsb:string,
    financialInstitution:string,
    name:string,
    street:string,
    suburb:string,
    postcode:string,
    state:string,
    flags:string,
}


export default async function bsbAutoComplete(bsb:string){
    const requestPayload = { bsb }
    const result:BSBAutocompleteResponseItem[] = await Request.get(Config.endpoints.bsbAutoComplete, requestPayload);
    return result;
}
