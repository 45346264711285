import React, { useContext } from 'react'
import { Button } from 'react-bootstrap';
import ButtonContainer from '../components/ButtonContainer';
import Step from '../components/Step';
import Context, { AppState } from '../Context';
import { dollarText, prettyDateFromArray } from '../Util';


export default function(){
    const {app, dispatch} = useContext(Context);
    const data = app.instalmentsData;
    const situation = getSituationString(data);
    const inceptionDate = prettyDateFromArray(data.inceptionDate);
    const expiryDate = prettyDateFromArray(data.expiryDate);
    const policyPeriod = inceptionDate + " to " + expiryDate;

    const onDirectDebit = () => dispatch({ type:'navigation.payWithDirectDebit' })
    const onCreditCard = () => dispatch({ type:'navigation.payWithCreditCard' })
    const onCancel = () => dispatch({ type:'app.cancel' })

    return <Step showBack={false}>
        <h2>Confirm instalment</h2>
        <table className="instalments-table">
            <tbody>
                <tr><td>Quote or policy number:</td><td>{data.quoteOrPolicyNumber}</td></tr>
                <tr><td>Product:</td><td>{data.productName}</td></tr>
                <tr><td>The Insured:</td><td>{data.insuredName}</td></tr>
                <tr><td>Situation:</td><td>{situation}</td></tr>
                <tr><td>Policy Period:</td><td>{policyPeriod}</td></tr>
                <tr><td>Number of instalments:</td><td>{data.instalmentPayments.length}</td></tr>
                <tr><td>Instalment Schedule:</td><td>
                    <table className="instalments-schedule-table">
                        <thead>
                            <tr>
                                <th>Debit Date</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        { data.instalmentPayments.map((payment,i) => 
                        <tr key={i}>
                            <td>{prettyDateFromArray(payment.instalmentDate)}</td>
                            <td>{dollarText(payment.instalmentAmount)}</td>
                        </tr>)}
                    </table>
                </td></tr>
                <tr><td>Total payable:</td><td>{dollarText(data.totalPayable)}</td></tr>
            </tbody>
        </table>
        <p>First instalment will be auto debited on <b>{prettyDateFromArray(data.instalmentPayments[0].instalmentDate)}</b></p>
        
        <ButtonContainer>
            <Button variant="secondary" onClick={onCancel}>Cancel</Button>
            <Button variant="primary" onClick={onDirectDebit}>Pay with Direct Debit</Button>
        </ButtonContainer>
    </Step>;
}



function getSituationString(data:AppState['instalmentsData']){
    function str(field:keyof typeof data, suffix:string=""){
        if(data[field]) return data[field] + suffix;
        return "";
    }

    return str('deliveryPoint', " ")
         + str('streetNumber', " ")
         + str('streetName', ", ")
         + str('suburb', ", ")
         + str('state', ", ")
         + str('postcode') 
}