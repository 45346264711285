import QueryString from 'query-string'

const queryExtract = QueryString.extract(window.location.href);
const queryParams = QueryString.parse(queryExtract);


export function parseQueryParamAsString<N extends string>(name:N, debugValue?:string){
    if(queryParams[name]===undefined && !debugValue && debugValue!="") throw(new Error("Must provide query parameter: " + name));
    if(queryParams[name]===undefined && debugValue) return { [name]:debugValue } as Record<N, string>;
    return { [name]: decodeURIComponent(queryParams[name]) } as Record<N, string>;
}



export function parseQueryParamAsStringOrUndefined<N extends string>(name:N):Record<N, undefined|string>{
    if(!queryParams[name]) return { [name]: undefined } as Record<N, undefined>;
    return { [name]: decodeURIComponent(queryParams[name]) } as Record<N, string>;
}


