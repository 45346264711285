import React, { useContext } from 'react'
import Context from '../Context';


const Checkbox = ({name, label}:{name:string, label:React.ReactNode}) => {
    const {app, dispatch} = useContext(Context);
    const value  = app.fields[name]
    const errors = app.validation.errors?.[name]

    function setField(value:any){
        dispatch({
            type: 'fields.setValue',
            name,
            value
        })
    }

    return (
        <div className={`custom-control custom-checkbox checkbox form-group ${errors?'invalid-element':''}`}>
            <input
                type="checkbox"
                className={"custom-control-input mt-1" + (errors ? " is-invalid" : "")}
                id={name}
                checked={!!value}
                onChange={(e) => setField(e.target.checked) }
            />
            <label className="custom-control-label" htmlFor={name}><span className="checkbox-label">{label}</span></label>
        </div>
    )
}


export default Checkbox;