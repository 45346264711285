export enum Brand {
    CHU = "chu",
    FLEX = "flex",
}

export enum Environment {
    LOCAL = "local",
    DEV = "dev",
    TEST = "test",
    STAGE = "stage",
    PRODUCTION = "production",
}

export enum NodeEnvironment {
    TEST = "test",
    DEVELOPMENT = "development",
    PRODUCTION = "production",
}