
import React, { useContext, useEffect, useState } from "react";
import Context from "./Context";
import services from "./services";

export function useBSBMask(bsbFieldName:string){
    const {app, dispatch} = useContext(Context)
    const bsbValue = app.fields[bsbFieldName]

    useEffect(() => {
        if(!bsbValue) return;
        var value = bsbValue;
        var hasHyphen = value[3]==="-";
        value = value.replace(/\D/g,'');
        if(hasHyphen) value = value.slice(0, 3) + "-" + value.slice(3);
        if(value.length>=4 && !hasHyphen){
            value = value.slice(0, 3) + "-" + value.slice(3);
        }
        value = value.slice(0, 7)
        dispatch({
            type:'fields.setValue',
            name: bsbFieldName,
            value,
        })
    }, [bsbValue, bsbFieldName, dispatch])
}


export function useBSBAutoComplete(bsbFieldName:string){
    type Suggestion = {value:string, label:string}
    const {app, dispatch} = useContext(Context)
    const bsbValue = app.fields[bsbFieldName];
    const [ suggestions, setSuggestions ] = useState([] as Suggestion[])

    async function onSuggestionsFetchRequested(payload:{value:string, reason:string}){
        const value = payload.value;
        if(!value || value.length < 2) return;
        const response = await services.bsbAutocomplete(value)
        const newSuggestions = response.map(entry => ({
            value: entry.bsb,
            label: entry.bsb + " - " + entry.name + ", " + entry.financialInstitution,
        }));
        setSuggestions(newSuggestions);
    }

    function onSuggestionsClearRequested(){
        setSuggestions([]);
    }

    function getSuggestionValue(value:Suggestion){
        return value.value
    }

    function renderSuggestion(suggestion:Suggestion){
        return <div className="address-suggestion">{suggestion.label}</div>
    }

    function setBSBValue(value:string){
        dispatch({
            type:'fields.setValue',
            name:bsbFieldName,
            value,
        })
    }
    
    function onSuggestionSelected(e:any, {suggestion, suggestionValue}:{suggestion:Suggestion, suggestionValue:string}){
        setBSBValue(suggestionValue)
    }

    return {
        suggestions,
        onSuggestionsFetchRequested,
        onSuggestionsClearRequested,
        getSuggestionValue,
        renderSuggestion,
        onSuggestionSelected,
    }

    /*
    return (
        <div className="input">
            <InputGroup>
                <Autosuggest
                    suggestions={ suggestions }
                    onSuggestionsFetchRequested={ onSuggestionsFetchRequested }
                    onSuggestionsClearRequested={ clearSuggestions }
                    getSuggestionValue={ getSuggestionValue }
                    renderSuggestion={ renderSuggestion }
                    onSuggestionSelected={(e, {suggestion, suggestionValue}) => setBSBValue(suggestionValue)}
                    inputProps={{
                        value: bsbValue || "",
                        id:"input-"+address,
                        type:'text',
                        className:``,
                        onChange: (event:any) => setBSBValue(event.target.value)
                    }}/>
            </InputGroup>
        </div>
    )
    */
}