import env from '../../env';
import Request from './request'
import Config from '../ConfigInstalments'


export type PaymentAuthoriseResult = {
    successful:true,
    paymentSuccessRedirect:string,
    policyNumber:string
} | {
    successful:false,
    reason:string
}


export default async (paymentMethodNonce:string, deviceData:any) => {
    const url = Config.endpoints.paymentAuthorise;

    let data:PaymentAuthoriseResult = await Request.post(url, {
        paymentMethodNonce,
        deviceData,
    });
    return data;
}
