import env from '../../env'
import Request from './request'
import Config from '../ConfigInstalments'


type BindRequestPayload = {
    token: string,
    details:{
        instalmentPaymentType:'DIRECT_DEBIT',
        title:string,
        firstName:string,
        lastName:string,
        phone:string,
        email?:string,
        address:string,
        suburb:string,
        state:string,
        postcode:string,
        financialInstitutionName:string,
        bsb:string,
        accountNumber:string,
        accountName:string,
        agreementCheck1:boolean,
        agreementCheck2:boolean,
    }|{
        instalmentPaymentType:'CREDIT_CARD',
        title:string,
        firstName:string,
        lastName:string,
        phone:string,
        email:string,
        address:string,
        suburb:string,
        postcode:string,
        customerId:string,
        paymentMethodToken:string,
        agreementCheck1:boolean,
        agreementCheck2:boolean
    },
}


type BindResponse = {
    successful: true,
    token: string,
    policyNumber: string,
    reason?:"", // Why does it return this when success is true?
} | {
    token: string,
    successful: false,
    reason?:"",
    policyNumber?: string // TODO: Verify if this should be optional
} | {
    errors: Record<string, string>
} | {
    message:string
    errorId:string
} | null


export default async function(requestPayload:BindRequestPayload){
    const result:BindResponse = await Request.post(Config.endpoints.bind, requestPayload);

    if(result == null) return null
    if('errors' in result || 'message' in result || 'reason' in result) return result;

    return result;
}