import React, { useContext } from 'react'
import Context from '../Context'


const chevron = (collapsed:boolean) => (
    <>
        <span className={collapsed?'d-none':''}><i className={`fas fa-chevron-down`}/></span>
        <span className={!collapsed?'d-none':''}><i className={`fas fa-chevron-up`}/></span>
    </>
)


export default function() {
    const {app:{legal:{collapsed}}, dispatch} = useContext(Context);

    function onClick(){
        dispatch({
            type:'legal.toggle'
        })
    }

    return (
        <div className="show-legal-button">
            <div className="btn btn-secondary" onClick={onClick}>{ collapsed ? "Legal" : "Legal" } { chevron(collapsed)}</div>
        </div>
    )
}

