import bind from './bind'
import calculateInstalments from './calculate-instalments'
import paymentInitiate from './paymentInitiate'
import paymentAuthorise from './paymentAuthorise'
import bsbAutocomplete from './bsbAutocomplete'


export default {
    bind,
    calculateInstalments,
    paymentInitiate,
    paymentAuthorise,
    bsbAutocomplete,
}