import React, { useContext } from 'react';
import env from '../../env';
import Context from '../Context';


const getLogo = () => {
    switch(env.BRAND){
        case 'chu': return require('../../assets/chu/logos/logo-chu-vert.png').default;
        case 'flex': return require('../../assets/flex/logos/flex-insurance-color.svg').default;
        default: throw(new Error("Could not find logo"));
    }
}


const Header = () => {
    const {app, dispatch} = useContext(Context)
    return (
        <header>
            <a href={env.ERROR_REDIRECT}>
                <img className="logo" alt="CHU logo" src={ getLogo() }/>
            </a>
            {
                !!app.params.title && <div className="form-name">{app.params.title}</div>
            }
        </header>
    )
}

export default Header;
