import * as React from 'react'
import { CustomInputGroup } from "./CustomInputGroup"
import Select, { Option, OptionValue } from "./Select"

type Props<T extends OptionValue> = {
    label:string,
    name:string,
    options:Option<T>[],
}


export default function <T extends OptionValue>({name, label, options}:Props<T>){
    return (
        <CustomInputGroup name={name} label={label}>
            <Select options={ options } field={ name }/>
        </CustomInputGroup>
    )   
}