import React, { FunctionComponent, ReactNode, useContext, useEffect } from 'react';
import Context from '../Context';
import LoadingIndicator from './LoadingIndicator';
import TitleContainer from './TitleContainer';
import { RouteName, routes } from '../RoutesInstalments';
import { useHistory, useLocation } from 'react-router';


type Props = {
    title?: ReactNode,
    warnUnload?:boolean,
    showBack?:boolean,
    isLoading?:boolean,
    containerWidth?:string,
    loading?:boolean
}



const Step:FunctionComponent<Props> = ({ loading, containerWidth, title, children, warnUnload=true, showBack=true, isLoading=false }) => {
    const containerStyle = !containerWidth ? {} : {
        maxWidth: containerWidth
    }

    return (
        <div className="step">
            <LoadingIndicator loading={ !!loading }>
                <TitleContainer containerWidth={containerWidth} title={ title } showBack={ showBack }/>
                <div className="content-container">
                    <div className="container" style={containerStyle}>
                        <div className="row">
                            <div className="col-12">
                                { children }
                            </div>
                            
                        </div>
                    </div>
                </div>
            </LoadingIndicator>
        </div>
    )
}


export default Step;
