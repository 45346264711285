import React, { useContext } from 'react'
import Step from '../components/Step'
import Input from '../components/Input'
import ButtonContainer from '../components/ButtonContainer'
import { Button } from 'react-bootstrap'
import Context from '../Context'
import Checkbox from '../components/Checkbox'
import Services from '../services'
import Validation from '../../validation'
import CreditCard from '../components/CreditCard'

const title = 'title'
const firstName = 'firstName'
const lastName = 'lastName'
const phone = 'phone'
const email = 'email'
const address = 'address'
const suburb = 'suburb'
const postcode = 'postcode'
const customerId = 'customerId'
const paymentMethodToken = 'paymentMethodToken'
const agreementCheck1 = 'agreementCheck1'
const agreementCheck2 = 'agreementCheck2'


export default function(){
    const {app, dispatch} = useContext(Context)


    async function onPaymentReceived(nonce:string){
        
    }

    /*
    async function onConfirmPayment(){
        const errors = Validation.validate(app.fields, {
            title:[],
            firstName:[],
            lastName:[],
            phone:[],
            email:[],
            address:[],
            suburb:[],
            postcode:[],
            customerId:[],
            paymentMethodToken:[],
            agreementCheck1:[],
            agreementCheck2:[],
        });

        if(errors){
            dispatch({
                type: 'validation.fail',
                errors
            });
            return;
        }


        dispatch({
            type:'loading.start',
            id: "payment-submission",
        })

        const result = await Services.bind({
            token: app.params.externalId,
            instalmentPaymentDetails:{
                instalmentPaymentType:       'CREDIT_CARD',
                [title]: app.fields[title],
                [firstName]: app.fields[firstName],
                [lastName]: app.fields[lastName],
                [phone]: app.fields[phone],
                [email]: app.fields[email],
                [address]: app.fields[address],
                [suburb]: app.fields[suburb],
                [postcode]: app.fields[postcode],
                [customerId]: app.fields[customerId],
                [paymentMethodToken]: app.fields[paymentMethodToken],
                [agreementCheck1]: app.fields[agreementCheck1],
                [agreementCheck2]: app.fields[agreementCheck2],
            },
        });

        dispatch({
            type: 'loading.end',
            id: "payment-submission",
        })

        if(result.successful){
            dispatch({
                type: 'app.success',
                token: result.token
            });
        }else{
            dispatch({
                type: 'alert.show',
                info:{
                    title: "Error",
                    message: result.reason
                }
            });
        }
    }
    */

    /*
    function onCancel(){
        dispatch({
            type: 'app.cancel'
        })
    }

    function onConfirmPaymentWithError(){
        dispatch({
            type:'loading.start',
            id: "payment-submission"
        })
        setTimeout(function(){
            dispatch({
                type: 'app.error'
            })
        }, 1500);
    }
    */

    return (
        <Step>
            <h2>PAY WITH CREDIT CARD</h2>
                <p>INSERT COPY HERE</p>
                <h4 className="pt-4 pb-2">Section A: My Details</h4>
                <div className="row">
                    <div className="col-md-6">
                        <Input name={title} label="Title"/>
                    </div>
                    <div className="col-md-6">
                        <Input name={firstName} label="First name"/>
                    </div>
                    <div className="col-md-6">
                        <Input name={lastName} label="Last name"/>
                    </div>
                    <div className="col-md-6">
                        <Input name={phone} label="Phone number"/>
                    </div>
                    <div className="col-md-6">
                        <Input name={email} label="Email"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <Input name={address} label="Address"/>
                    </div>
                    <div className="col-md-8">
                        <Input name={suburb} label="Suburb"/>
                    </div>
                    <div className="col-md-4">
                        <Input name={postcode} label="Postcode" type='postcode'/>
                    </div>
                </div>
                <h4 className="pt-4 pb-2 mt-4">Section B: Authority to</h4>
                <div>
                    <p>CREDIT CARD FORM</p>

                    {/*
                    <div className="row">
                        <div className="col-md-12">
                            <Input name={financialInstitutionName} label="Financial Institution Name where account held"/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Input name={bsb} label="BSB Number"/>
                        </div>
                        <div className="col-md-6">
                            <Input name={accountNumber} label="Account Number"/>
                        </div>
                        <div className="col-md-12">
                            <Input name={accountName} label="Account in the name(s) of:"/>
                        </div>
                    </div>
                    */}
                    <CreditCard totalPayable={ app.instalmentsData.totalPayable }
                                onLoadingBegin={ ()=>dispatch({type:'loading.start', id:'credit-card' }) }
                                onLoadingEnd={ ()=>dispatch({type:'loading.end', id:'credit-card' }) }
                                onPaymentReceived={ (nonce, type)=>{} }
                                onPaymentFail={ ()=>{} }
                                onCancel={ ()=>dispatch({ type:'navigation.goBack' })}
                    />
                    <Checkbox name={agreementCheck1} label="I/We have read and understood the CHU Underwriting Agencies Pty Ltd Direct Debit Request (DDR) Service Agreement found at xxxxxx and the Important notice found below. I/We also authorise CHU Underwriting Agencies Pty Ltd to alter the amount of the debit if I/we request a change to the sums insured."/>
                    <Checkbox name={agreementCheck2} label="I/We agree to this DDR Service Agreement" />
                </div>
                <h4 className="pt-4 pb-2 mt-4">Important notice</h4>
                <div>
                    <ul>
                        <li>Direct Debit deductions will be made through the Bulk Electronic Clearing System (BECS) and will be subject to Drawing arrangements. Please ensure sufficient funds are available. Our standard policy wording contains a provision that where a premium instalment remains outstanding for a period in excess of one month the insurance cover may be cancelled.</li>
                        <li>Contact your financial institution if you are uncertain how to complete this Direct Debit Request form</li>
                    </ul>

                    <p>If more than one person is required to authorise Direct Debits Requests on this account you must obtain the authorisation of all required parties before completing this form. By clicking "I/We agree to this DDR Service Agreement" you are confirming you have obtained this authorisation.</p>
                    <p>By clicking "I/We agree to this DDR Service Agreement" you:</p>
                    <p>1) have understood and agreed to the terms an conditions governing the debit arrangements between you and CHU Underwriting Agencies Pty Ltd as set out in this Direct Debit Request and in the Direct Debit Request Service Agreement; and</p>
                    <p>2) understand you are executing this document by electronric signature and you are aware that by electronically signing this document you are executing a legally binding document.</p>
                </div>
        </Step>
    )
}