import moment from 'moment'
import { get } from 'lodash'

export type Rule = (value:any)=>string|null;
export type RuleMap<F extends {}> = {[K in keyof F]:Rule[]};


const createCheckForRegex = 
(regex:RegExp) => 
    (value:any) => 
        regex.test(value)


const rules ={
    optional: (value:any) => true,
    number: createCheckForRegex(/^\d+$/),
    numberDecimal: createCheckForRegex(/^\d*\.?\d*$/),
    year: createCheckForRegex(/^\d{4}$/),
    name: createCheckForRegex(/^[a-z ,.'-]+$/i),
    phoneNumber: createCheckForRegex(/^[\w+ \(\)-]+$/),
    email: createCheckForRegex(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
    ownersCorporationNumber: createCheckForRegex(/^([a-zA-Z0-9/]){1,11}$/),
    required: (value:any) => value === 0 || value === "0" || value === false || value === true || ( value !== "" && value !== undefined && value !== null && typeof(value) !== "undefined" && (typeof(value) !== "object" || value.length>0)),
    isTrueOrFalse: (value:any) => value === true || value === false,
    isTrue: (value:any) => value === true,
    date: (value:any) => moment( moment(value, "DD/MM/YYYY").format() ).isValid(),
    hasAtLeastOneEntry: (value:any) => value != null && value !== undefined && value.length > 0,
    notBlank: (value:any) => value !== 'BLANK',
    yearPast: (value:any) => value <= moment().year(),
    bsb: (value:any) => !!value
                     && rules.number(value[0])
                     && rules.number(value[1])
                     && rules.number(value[2])
                     && value[3] === "-"
                     && rules.number(value[4])
                     && rules.number(value[5])
                     && rules.number(value[6])
                     && value.length === 7,
    accountNumber: (value:any) => !!value && value.length <= 9 && value.length >= 6 && rules.number(value),
    accountName: (value:any) => !!value && value.length <= 32,

    numChars: (x:number) => (value:any) => value!==undefined && value.length === x,
    greaterThan: (x:number) => (value:any) => value > x,
    greaterThanEqual: (x:number) => (value:any) => value >= x,
    lessThan: (x:number) => (value:any) => value < x,
    lessThanEqual: (x:number) => (value:any) => value <= x,
    rangeInclusive: (x:number, y:number) => (value:any) => value >= x && value <= y,
    predicate: (fn:(value:any)=>boolean) => (value:any) => fn(value),
    regex: (regex:RegExp) => createCheckForRegex(regex),
    lengthLessThanEqual: (length:number) => (value:any) => value == null || value.length <= length,
    valueExists: (valueOfOtherField:any) => (value:any) => rules.required(valueOfOtherField)
}


const create = (rule:(value:any)=>boolean) => (message:string) => (value:any) => rule(value) ? null : message;
const createCompound = <R extends (...args:any)=>(value:any)=>boolean, P extends Parameters<R>>(rule:R) => (...args:P) => (message:string) => (value:any) => rule(...args)(value) ? null : message;

const enforce = {
    optional                : create(rules.optional),
    number                  : create(rules.number),
    numberDecimal           : create(rules.numberDecimal),
    year                    : create(rules.year),
    yearPast                : create(rules.yearPast),
    name                    : create(rules.name),
    phoneNumber             : create(rules.phoneNumber),
    email                   : create(rules.email),
    ownersCorporationNumber : create(rules.ownersCorporationNumber),
    required                : create(rules.required),
    isTrueOrFalse           : create(rules.isTrueOrFalse),
    isTrue                  : create(rules.isTrue),
    date                    : create(rules.date),
    hasAtLeastOneEntry      : create(rules.hasAtLeastOneEntry),
    notBlank                : create(rules.notBlank),
    bsb                     : create(rules.bsb),
    accountNumber           : create(rules.accountNumber),
    accountName             : create(rules.accountName),

    numChars                : createCompound(rules.numChars),
    greaterThan             : createCompound(rules.greaterThan),
    greaterThanEqual        : createCompound(rules.greaterThanEqual),
    lessThan                : createCompound(rules.lessThan),
    lessThanEqual           : createCompound(rules.lessThanEqual),
    rangeInclusive          : createCompound(rules.rangeInclusive),
    predicate               : createCompound(rules.predicate),
    regex                   : createCompound(rules.regex),
    lengthLessThanEqual     : createCompound(rules.lengthLessThanEqual),
    valueExists             : createCompound(rules.valueExists),
}


function validate<F extends Record<string,any>>(data:F, rulesMap:RuleMap<F>){
    const result = {} as Record<keyof Partial<F>, string[]>;

    const fieldNames = Object.keys(rulesMap) as (keyof F)[];
    fieldNames.forEach(fieldName => {
        const value = get(data, fieldName);
        const rules = rulesMap[fieldName];
        const messagesWithNulls = rules!.map(rule => rule(value))
        const messages = messagesWithNulls.filter(result => !!result) as string[]
        if(messages.length > 0) result[fieldName] = messages;
    })
    
    return Object.keys(result).length === 0 ? null : result;
}

export default {
    ...rules,
    enforce,
    validate,
}
