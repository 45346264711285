import Request from './request'
import Config from '../ConfigInstalments'


type CalculateInstalmentsResponse = {
    expiryDate: [number, number, number],
    inceptionDate: [number, number, number],
    insuredName: string,
    productName: string,
    quoteOrPolicyNumber: string,
    postcode: string,
    deliveryPoint:null|string,
    state: "VIC" | "NSW" | "QLD" | "NT" | "SA" | "WA"| "TAS" | "ACT",
    streetName: string,
    streetNumber: string,
    suburb: string,
    totalPayable: number,
    instalmentPayments:{
        instalmentDate: [number, number, number],
        instalmentAmount: number,
    }[],
}


export default async function(externalId:string):Promise<CalculateInstalmentsResponse>{
    const requestPayload = { externalId }
    return await Request.get(Config.endpoints.calculateinstalments, requestPayload);
}