import React, { useContext } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import Context from "../Context";

type Props = {
    name:string,
    label:string|React.ReactNode,
    children:React.ReactNode,
}

export const CustomInputGroup = ({name, label, children}:Props) => {
    const {app, dispatch} = useContext(Context)
    const errors = app.validation.errors?.[name];

    return <>
        <div className={`mb-4 ${errors?'invalid-element':''}`}>
            <label htmlFor={name}>{label}</label>
            <InputGroup>
                { children }
                <FormControl.Feedback type="invalid" style={{display:errors?'block':'none'}}>
                    { errors?.[0] &&  <label>{errors?.[0]}</label> }
                </FormControl.Feedback>
            </InputGroup>
        </div>
    </>
}