import React, { useContext } from 'react';
import Context from '../Context';

type Props = {
    title?: React.ReactNode,
    showBack?: boolean,
    containerWidth?: string,
}


const TitleComponent:React.FunctionComponent<Props> = ({containerWidth, title, showBack}) => {
    const {app, dispatch} = useContext(Context);

    function onBack(){
        dispatch({
            type: 'navigation.goBack'
        })
    }

    const style = !containerWidth ? {} :{
        maxWidth: containerWidth
    };

    return (
        <div className="title-container">
            <div className="container" style={style}>
                <div className="row">
                    <div className="col-12 inner-container">
                        { showBack &&
                        <a onClick={onBack} className="icon-container back-button">
                            <span className="icon"><i className="fas fa-chevron-left"></i></span>Back
                        </a>
                        }
                        { title ? <div className="line">|</div> : null }
                        <div className="title-text-container">
                            <div>{ title }</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default TitleComponent;