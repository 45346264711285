import React, { useContext } from 'react'
import Context from '../Context';
import { Type } from './Input'

export type OptionValue = string | string[] | number | undefined;

export type Option<T extends OptionValue> = {
    type: string,
    value:T,
    description:string,
    default?:T,
    help?: string,
    priority?: number,
}

type Props<T extends OptionValue> = {
    options: Option<T>[],
    field: string,
    label?: React.ReactNode,
    size?: "size-50",
    center?:boolean,
    caption?: React.ReactNode,
    type?: Type,
}


function createOption<T extends OptionValue>(type:Type, option:Option<T>, index:number){
    return <option value={option.value} key={index}>{ option.description}</option>
}


function Select<T extends OptionValue>({ label, field, options, type='text' }:Props<T>){
    const {app, dispatch} = useContext(Context);
    const fields = app.fields
    const hasErrors = app.validation.errors?.[field]

    const setValue = (fieldName:string) => (value:any) => dispatch({
        type: 'fields.setValue',
        name: fieldName,
        value
    })

    const [ fieldValue, setFieldValue ] = typeof field === "string"
        ? [ fields[field], setValue(field) ] as const
        : field;

    function onChange(value:string){
        if(type === 'dollar' || type === 'postcode' || type === 'year' || type === 'percent'){
            setFieldValue(parseInt(value) as any);
        }else{
            setFieldValue(value as any);
        }
    }

    return (
        <div className="custom-select-container">
            { label &&
                <label className="main-label">{ label }</label>
            }
            <select
                className={`custom-select ${hasErrors?'is-invalid':''}`}
                value={fieldValue}
                onChange={ e => onChange(e.target.value)
            }>
                <option disabled selected={!fieldValue}/>
                { options.map((option, index) => createOption(type, option, index)) }
            </select>
        </div>
    )
}

export default Select;
