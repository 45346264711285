import env from "../env";

export default {
    endpoints: env.ENVIRONMENT === 'local' ? {
        // Endpoints for local environment
        calculateinstalments    : env.ENDPOINT_HOST + "/api/common/instalments/calculateInstalments",
        paymentAuthorise        : env.ENDPOINT_HOST + '/webforms/isaverresi/authorise/v2',
        paymentInitiate         : env.ENDPOINT_HOST + '/webforms/isaverresi/initiate/v2',
        bind                    : env.ENDPOINT_HOST + '/api/common/instalments/bind',  
        bsbAutoComplete         : env.ENDPOINT_HOST + '/api/common/bsbAutoComplete',
        smartAddress            : env.ENDPOINT_HOST + '/webforms/common/smartaddress/v1',
    } : {
        // Endpoints for other environments
        calculateinstalments    : env.ENDPOINT_HOST + "/webforms/common/calculateinstalments",
        paymentAuthorise        : env.ENDPOINT_HOST + '/webforms/isaverresi/authorise/v2',
        paymentInitiate         : env.ENDPOINT_HOST + '/webforms/isaverresi/initiate/v2',
        bind                    : env.ENDPOINT_HOST + '/webforms/common/bind',  
        bsbAutoComplete         : env.ENDPOINT_HOST + '/webforms/common/bsbautocomplete',
        smartAddress            : env.ENDPOINT_HOST + '/webforms/common/smartaddress/v1',
    }
}