import React, { ReactNode, useContext, useEffect } from 'react'
import Context from '../Context';

type Props = {
    children: ReactNode
}

// This component ensures that the form initialises properly. If it doesn't initialise
// then show an alert.
const InitSuccessGuard = ({children}:Props) => {
    const {app, dispatch} = useContext(Context)
    const failed = app.instalmentsData!=null && app.instalmentsData.totalPayable==null

    useEffect(() => {
        if(failed){
            dispatch({
                type: 'alert.show',
                info: {
                    message: app.messages.genericError,
                    buttonLabel: "Go Back",
                    redirect: app.params.error,
                }
            })
        }
    }, [failed])

    if(failed) return null;
    return <>{children}</>
}

export default InitSuccessGuard;