import produce from "immer"
import React, { Dispatch } from "react";
import { parseQueryParamAsString, parseQueryParamAsStringOrUndefined } from "../params";
import { UnpackedPromise } from "../types";
import Services from './services'
import DevTools from '../DevTools'
import env from "../env";
import { redirectError } from "./Util";

export type AppState = UnpackedPromise<typeof createInitialState>;


export async function createInitialState(){
    const params = {
        ...parseQueryParamAsString('externalId'),
        ...parseQueryParamAsString('error', env.ENVIRONMENT==='local' ? 'localhost:3000/error' : undefined),
        ...parseQueryParamAsString('cancel', env.ENVIRONMENT==='local' ? 'localhost:3000/cancel' : undefined),
        ...parseQueryParamAsString('success', env.ENVIRONMENT==='local' ? 'localhost:3000/success' : undefined),
        ...parseQueryParamAsStringOrUndefined('title'),
    }

    const genericError = env.BRAND == 'chu'
                            ? <>Oops, something went wrong. If this error persists, please <a href='http://www.chu.com.au/contact-us/'>contact your local CHU office.</a></>
                            : <>Oops, something went wrong. If this error persists, please <a href='https://flexinsurance.com.au/contact-us/'>contact Flex Insurance.</a></>

    try{
        const instalmentsData = await Services.calculateInstalments(params.externalId);
        return {
            action:{
                previous: {type:'action.clear'} as Action,
            },
            alert:{
                info:undefined as undefined|{
                    title?: React.ReactNode,
                    message: React.ReactNode,
                    buttonLabel?: string,
                    redirect?: string,
                }
            },
            messages:{
                genericError
            },
            legal:{
                collapsed:true,
            },
            loading:{
                items:[] as string[]
            },
            validation:{
                errors: null as null|Record<string, string[]>,
            },
            navigation:{
                nextRoute:null as string|null,
            },
            fields:{} as Record<string, any>,
            referrer:{
                token: null as null|string,
            },
            params,
            instalmentsData,
        }
    }catch(e){
        console.log("ERROR: " + e)
        //redirectError(params.error, "Internal error");
        throw(e)
    }

}


export type Action =
    | { type: 'action.clear' }
    | { type: 'alert.show', info:AppState['alert']['info'] }
    | { type: 'alert.hide' }
    | { type: 'legal.toggle' }
    | { type: 'loading.start', id:string }
    | { type: 'loading.end', id:string }
    | { type: 'navigation.goBack' }
    | { type: 'navigation.payWithDirectDebit' }
    | { type: 'navigation.payWithCreditCard' }
    | { type: 'fields.setValue', name:string, value:any }
    | { type: 'fields.set', values:Record<string, any> }
    | { type: 'validation.fail', errors: Record<string, string[]> }
    | { type: 'validation.clearErrors', key: string }
    | { type: 'validation.success' }
    | { type: 'validation.clear' }
    | { type: 'app.cancel' }
    | { type: 'app.confirm' }
    | { type: 'app.error' }
    | { type: 'app.success', token:string, policyNumber:string }


export function reducer(base:AppState, action:Action):AppState{
    return produce(base, state => {
        switch(action.type){
            case 'alert.show': state.alert.info = action.info; break;
            case 'alert.hide': state.alert.info = undefined; break
            case 'legal.toggle': state.legal.collapsed = !state.legal.collapsed; break;
            case 'loading.start': state.loading.items.indexOf(action.id)===-1 && state.loading.items.push(action.id); break;
            case 'loading.end': state.loading.items.splice(state.loading.items.indexOf(action.id), 1); break;
            case 'fields.setValue': state.fields[action.name] = action.value; break;
            case 'fields.set': state.fields = { ...state.fields, ...action.values }; break;
            case 'validation.fail': state.validation.errors = action.errors; break;
            case 'validation.clearErrors': if(state.validation.errors) delete state.validation.errors[action.key]; break;
            case 'validation.success': state.validation.errors = null; break;
            case 'validation.clear': state.validation.errors = null; break;
        }
        state.action.previous = action;
    })
}


export function reducerWithDevTools(base:AppState, action:Action):AppState{
    const nextState = reducer(base, action);
    DevTools.update(action.type, nextState);
    return nextState;
}


export default React.createContext<{app:AppState, dispatch:Dispatch<Action>}>(null!);
