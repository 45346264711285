import React, { useContext, useRef } from 'react'
import { Collapse } from 'react-bootstrap'
import Context from '../Context';
import env from "../../env";


export default function() {
    const {app:{legal:{collapsed}}, dispatch} = useContext(Context)
    const ref = useRef<HTMLDivElement>(null);

    const onEntered = () => {
        //scroll.show(ref)
        if(ref.current) ref.current!.scrollIntoView();
    }

    const getFooter = () => {
        switch(env.BRAND){
            case 'chu': return (<> <p>CHU Underwriting Agencies Pty Ltd (ABN 18 001 580 070, AFS Licence No: 243261) acts under a binding authority as an agent of the insurers QBE Insurance (Australia) Limited (ABN 78 003 191 035, AFS Licence No: 239545) and Chubb Insurance Australia Limited (ABN 23 001 642 020, AFS Licence No. 239687).</p>
                <p>Terms, conditions, limits and exclusions apply to the products referred to above. Any advice on this website is general advice only and has been prepared without taking into account your objectives, financial situation or needs. Before making a decision to acquire any product(s) or to continue to hold any product we recommend that you consider whether it is appropriate for your circumstances and read the relevant Product Disclosure Statement which can be viewed on this website or obtained by contacting CHU directly.</p>
                <p>Copyright © {new Date().getFullYear()} CHU Underwriting Agencies Pty Ltd</p> </>) ;
            case 'flex': return (<> <p>CHUiSAVER Underwriting Agency Pty Ltd (ABN 85 613 645 239, AFSL 491113) trading as Flex Insurance acts under a binding authority as an agent for certain underwriters at Lloyd’s and QBE Insurance (Australia) Limited (ABN 78 003 191 035, AFSL 239545), the insurers of the products.</p>
                <p>Terms, conditions, limits and exclusions apply to the products referred to above. Any advice contained on this website is general advice only and has been prepared without taking into account your objectives, financial situation or needs. Before making a decision to purchase the product or to continue to hold the product we recommend that you consider whether it is appropriate for your circumstances and read the Product Disclosure Statement (‘PDS’), Target Market Determination (‘TMD’) and Financial Services Guide (‘FSG’), which can be obtained by downloading it from the website or by contacting Flex Insurance directly.</p>
                <p>Copyright © {new Date().getFullYear()} CHUiSAVER Underwriting Agencies Pty Ltd</p> </>) ;
            default: throw(new Error("Could not find banner"));
        }
    }
    
    return (
        <Collapse in={!collapsed} onEntered={onEntered}>
            <div className="legal" ref={ ref } >
                <div className="container">
                    <div className="row">
                        <div className="col">
                            {getFooter()}
                        </div>
                    </div>
                </div>
            </div>
        </Collapse>
    )
}