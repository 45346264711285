import React, { useCallback, useEffect } from 'react'
import CurrencyFormatter from 'currency-formatter'
import Services from '../services';


type Props = {
    totalPayable:number,
    onLoadingBegin:()=>void,
    onLoadingEnd:()=>void,
    onPaymentReceived:(nonce:string, type:string)=>void,
    onPaymentFail:()=>void,
    onCancel:()=>void,
}


const setupBrainTree = (paymentClientToken:string, paymentEnvironment:string, onPaymentReceived:Props['onPaymentReceived']) => {
    let deviceData:any;

    const onReady = (braintreeInstance:{deviceData:any}) => {
        deviceData = braintreeInstance.deviceData;
    }

    const onPaymentMethodReceived = async (paymentMethodDetails:{nonce:string, type:string}) => {
        onPaymentReceived(paymentMethodDetails.nonce, paymentMethodDetails.type);
        //onLoadingBegin();
        //const result = await Services.paymentAuthorise(paymentMethodDetails.nonce, deviceData);
        //onLoadingEnd();

        //if(result.successful) onPaymentSuccess();
        //else onPaymentFail();
    }
    
    (window as any)["braintree"].setup(paymentClientToken, "dropin", {
        container: "braintree",
        onReady,
        onPaymentMethodReceived,
        dataCollector: {
            kount: {
                environment: paymentEnvironment
            }
        },
    });
}


const CreditCard = ({totalPayable, onLoadingBegin, onLoadingEnd, onPaymentReceived, onPaymentFail, onCancel}:Props) => {
    
    
    useEffect(() => {
        async function init(){
            const result = await Services.paymentInitiate();
            onLoadingEnd();
            setupBrainTree(result.paymentClientToken, result.paymentEnvironment, onPaymentReceived);
        }
        init();
    }, [onLoadingEnd, onPaymentReceived]); 

    const totalAsCurrency = CurrencyFormatter.format(totalPayable, {
        thousand: ",",
        precision: 2
    })

    return (
        <form id="payment-form" method="post"> 
            <div id="braintree"></div>
            <button className="btn btn-secondary btn-lg mr-2 mt-4" onClick={ onCancel }>Cancel</button>
            <input className="btn btn-primary btn-lg mt-4 pull-right" type="submit" value={'Pay $' + totalAsCurrency} />
        </form>       
    )
}


export default CreditCard;
