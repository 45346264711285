import React, { useContext } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Context from "../Context";
import 'react-bootstrap/Modal'


export default function (){
    const {app:{alert:{info}}, dispatch} = useContext(Context);

    const onOk = () => {
        if(info && info.redirect){
            window.location.href = info.redirect;
        }else{
            dispatch({
                type:'alert.hide'
            })
        }
    }

    return (
        <Modal id="app-modal" show={ !!info }>
            { info && info.title && 
            <Modal.Header>
                <Modal.Title>{ info.title }</Modal.Title>
            </Modal.Header>
            }
            <Modal.Body>{ info && info.message }</Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={ onOk }>{ (info && info.buttonLabel) || "Ok" }</Button>
            </Modal.Footer>
        </Modal>
    )
}
