import React from 'react'
import env from '../../env'


export default () => (
    <div className="contact">
        <div className="footer-phone">
            <div>Get in touch:</div>
            <div>
                <a href={ env.CONTACT_LINKED_IN } target="_blank" rel="noreferrer">
                    <span className="contact-icon contact-linkin">
                        <i className="fab fa-linkedin fa-lg"/>
                    </span>
                </a>
                <a href={"mailto:"+env.CONTACT_EMAIL}>
                    <span className="contact-icon contact-email">
                        <i className="fas fa-envelope-square fa-lg" />
                    </span>
                </a>
                <a href={ 'tel:'+env.CONTACT_PHONE }>
                    <span className="contact-icon contact-phone">
                        <i className="fas fa-phone-square-alt fa-lg" />
                    </span>
                    <b dangerouslySetInnerHTML={{__html:env.CONTACT_PHONE_READABLE}} ></b>
                </a>
            </div>
        </div>
        <a className="footer-linkedin" href={ env.CONTACT_LINKED_IN } target="_blank" rel="noreferrer">
            Follow us <i className="fab fa-linkedin-in fa-2x"></i>
        </a>
    </div>
);