import React, { useReducer } from 'react'
import Legal from './components/Legal'
import Alert from './components/Alert'
import Contact from './components/Contact'
import Header from './components/Header'
import DividerBar from './components/DivideBar'
import ShowLegalButton from './components/ShowLegalButton'
import RoutesInstalments from './RoutesInstalments'
import LoadingIndicator from './components/LoadingIndicator'
import Context, { reducerWithDevTools, AppState } from './Context'

import env from'../env'
import EffectsInstalments from './EffectsInstalments'
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import InitSuccessGuard from './components/InitSuccessGuard'


const getBrandName = () => {
    switch(env.BRAND){
        case "chu": return "CHU";
        case "flex": return "Flex";
    }
}


export default ({initialState}:{initialState:AppState}) => {
    document.title = "Instalments » " + getBrandName();
    const [app, dispatch] = useReducer(reducerWithDevTools, initialState);

    return (
        <div className={"app app-brand-"+env.BRAND} data-testid="app">
            <Context.Provider value={{app, dispatch}}>
                <Alert />
                <InitSuccessGuard>
                    <HashRouter>
                        <ScrollToTop>
                            <EffectsInstalments>   
                                <LoadingIndicator loading={app.loading.items.length>0} fixed={true} />
                                <Header/>
                                <DividerBar />
                                <RoutesInstalments />
                                <ShowLegalButton />
                                <Contact />
                                <Legal />
                            </EffectsInstalments>
                        </ScrollToTop>
                    </HashRouter>
                </InitSuccessGuard>
            </Context.Provider>
        </div>
    )
}