import React, { useContext, useEffect } from 'react';
import Context from './Context';
import { RouteName } from './RoutesInstalments';
import { useHistory, useLocation } from 'react-router';
import { appendParams, redirectCancel, redirectError, redirectSuccess } from './Util';


export default function EffectsInstalments({children}:{children:React.ReactNode}){
    useNavigation();
    useWindowDisptach();
    useHandleReferrer();
    useScrollToValidationErrors();
    
    return <>{children}</>;
}

// Whenever app.validation.errors object changes, this effect will search the DOM and
// scroll to the top-most element with the class name 'invalid-element'. Components
// should attach this class name to a child element in order to utilise this feature.
function useScrollToValidationErrors(){
    const {app, dispatch} = useContext(Context);

    useEffect(() => {
        if(app.validation.errors){
            const invalidElements = Array.prototype.slice.call(document.getElementsByClassName('invalid-element')) as Array<HTMLElement>;
            invalidElements.sort((a, b) => {
                if(a.clientTop > b.clientTop) return -1;
                if(a.clientTop < b.clientTop) return 1;
                return 0;
            });
            const topElement = invalidElements[0];
            if(topElement){
                window.scrollTo({
                    top: topElement.getBoundingClientRect().top + window.pageYOffset - 20,
                    behavior: 'smooth'
                })
            }
        }
    }, [app.validation.errors]);
}


// This effect navigates back to the external website once and app.success, app.cancel,
// or app.error action fires.
function useHandleReferrer(){
    const {app, dispatch} = useContext(Context);

    useEffect(() => {
        switch(app.action.previous.type){
            case 'app.error':
                redirectError(app.params.error, "Internal error");
                //resultURL = appendParams(app.params.error, { successful:'false', reason:"Internal error" })
                break;
            case 'app.cancel':
                redirectCancel(app.params.cancel)
                //resultURL = appendParams(app.params.cancel, {})
                break;
            case 'app.success':
                redirectSuccess(app.params.success, app.action.previous.policyNumber)
                //resultURL = appendParams(app.params.success, { successful:'true' })
                break;
        }
        /*
        if(resultURL){
            console.log(resultURL)
            window.location.href = resultURL;
        }
        */
    }, [app.action.previous, app.params.cancel, app.params.error, app.params.externalId, app.params.success])
}


// Makes dispatch a global function so that you can easily update the app
// state in the browser console, or via a browser script.
function useWindowDisptach(){
    const {app, dispatch} = useContext(Context);

    useEffect(function(){
        (window as any).dispatch = dispatch;
    }, [app, dispatch]);
}


// Handle navigation performed in the app.
function useNavigation(){
    const {app:{action, navigation:{nextRoute}}, dispatch} = useContext(Context);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        switch(action.previous.type){
            case 'navigation.payWithCreditCard':
                history.push('/credit-card' as RouteName);
            break;
            case 'navigation.payWithDirectDebit':
                history.push('/direct-debit' as RouteName);
            break;
            case 'navigation.goBack':
                dispatch({
                    type: 'action.clear'
                });
                history.goBack();
            break;
        }
    }, [action.previous, dispatch, history, location.pathname]);
}

